export default function useBenefits() {
  // METHODS
  function getBenefitsTableData(benefits, columns, selectedId, benefitShowProductLinks = false) {
    const data = {}
    const contentColumns = columns.filter(column => column !== 'benefitTypes')
    let selectedContentColumn = -1
    if (contentColumns.length > 1) {
      selectedContentColumn = contentColumns.findIndex(column => {
        return column === selectedId
      })
    }

    data.header = _getHeaderRow(benefits, columns, selectedContentColumn, benefitShowProductLinks)
    data.selectedContentColumn = selectedContentColumn
    data.content = _getContent(benefits, contentColumns)
    return data
  }

  function _getContent(benefits, contentColumns) {
    const features = benefits.benefitTypes?.features
    return features?.flatMap((feature, featureIndex) => {
      const featureElements = []
      featureElements.push({
        isMainType: true,
        title: feature.type,
        icon: feature.typeIcon,
        content: contentColumns.map(column => benefits[column]?.features[featureIndex].type),
      })
      const subTypes = feature.subType.map((subType, subTypeIndex) => {
        const subTypeIcons = feature?.subTypeIcons
        return {
          isMainType: false,
          title: subType,
          icon: subTypeIcons ? subTypeIcons[subTypeIndex] : null,
          content: contentColumns.map(column => benefits[column]?.features[featureIndex].subType[subTypeIndex]),
        }
      })
      featureElements.push(...subTypes)
      return featureElements
    })
  }

  function _getHeaderRow(benefits, columns, selectedContentColumn, benefitShowProductLinks) {
    const contentArray = []
    columns.forEach(column => {
      if (column !== 'benefitTypes') {
        contentArray.push(_getTitleData(benefits[column], benefitShowProductLinks))
      }
    })
    return {
      contentArray,
      selectedContentColumn,
    }
  }

  function _getTitleData(element, benefitShowProductLinks) {
    return {
      title: element?.title ? element.title : '&nbsp;',
      subTitle: element?.subTitle,
      productLink: benefitShowProductLinks ? element?.productLink : null,
    }
  }

  return {
    getBenefitsTableData,
  }
}
