<template>
  <!-- prettier-ignore -->
  <div
    class="franchise"
    :class="classes"
    cy-option="franchise"
  >
    <transition-group
      appear
      name="hello"
    >
      <template
        v-for="option in sortedOptions"
        :key="option.value"
      >
        <button
          class="franchise-option"
          :class="{'franchise-option--active': !!option.selected}"
          :cy-test="option.value"
          @click="select(option)"
        >
          <the-typography
            class="franchise__value"
            tag="span"
            type="bodyLargeShort"
            v-text="getFormattedCurrency(option.value, null, 0)"
          />
          <product-price
            v-if="option.price && priceDifference(option) != 0"
            class="franchise__difference"
            :prefix="(priceDifference(option) > 0) ? '+' : '-'"
            :price="priceDifference(option) > 0 ? priceDifference(option) : Math.abs(priceDifference(option))"
            :suffix="$t('product.currency')"
          />
        </button>
      </template>
    </transition-group>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { SortUtil } from '@/utils/Sorting'
import { events$ } from '@/services'

import useFranchise from '@/hooks/useFranchise'

import ProductPrice from '@/components/Product/ProductPrice'

import { EVENT_BASKET, EVENT_PRODUCT } from '@/config/events'

// INIT
const emit = defineEmits(['select'])
const props = defineProps({
  inline: {
    type: Boolean,
    default: false,
  },
  forcedInline: {
    type: Boolean,
    default: false,
  },
  forcedMobile: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    required: true,
  },
  source: {
    type: String,
    required: true,
  },
})

// COMPUTED
const classes = computed(() => {
  return {
    'franchise--inline': isInline.value,
    'franchise--multi-rows': isMultiRows.value,
    'franchise--mobile-view': isMobileView.value,
  }
})

const isInline = computed(() => {
  return props.inline && (sortedOptions.value.length <= 6 || props.forcedInline) && !props.forcedMobile
})

const isMobileView = computed(() => {
  return props.forcedMobile
})

const isMultiRows = computed(() => {
  return !isInline.value && !props.forcedMobile
})

const selectedOption = computed(() => {
  return props.options.find(item => item.selected)
})

const sortedOptions = computed(() => {
  const selectedIndex = props.options.findIndex(o => o.value === selectedOption.value.value && o.selected === true)
  return props.options
    .filter((option, index) => {
      const matches = props.options.filter(o => o.value === option.value).length
      return matches === 1 || selectedIndex === index
    })
    .sort(SortUtil.sortReverseByParam('value'))
})

// METHODS
function getFormattedCurrency(input, currency, fixed) {
  const { formatCurrency } = useFranchise()
  return formatCurrency(input, currency, fixed)
}

function select(option) {
  const _option = Object.assign({}, option, { selected: true })
  emit('select', _option)
  events$.emit(EVENT_BASKET.TRACK_AFTER_UPDATE, {
    ruleId: EVENT_PRODUCT.FRANCHISE_CHANGED,
    payload: props.source,
  })
}

function price(option) {
  return option?.price
}

function priceDifference(option) {
  const diff = price(option) - price(selectedOption.value)
  return Number.parseFloat(diff)
}
</script>

<style name="animations" scoped>
.hello-enter-active,
.hello-leave-active {
  transition: all 0.75s ease;
}

.hello-enter-from,
.hello-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
</style>

<style name="mobile" scoped>
.franchise {
  overflow: hidden;
  display: grid;

  @supports (grid-template-columns: repeat(2, [col] auto)) {
    grid-gap: 16px;
    grid-template-columns: repeat(2, [col] auto);
  }

  &-option {
    border: 1px solid var(--c-primary-neutral-1);
    background: none;

    height: 50px;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: var(--c-primary-neutral-1);

    &--active.franchise-option {
      border-color: var(--c-primary-color-3);
      background-color: var(--c-primary-color-3);
      color: var(--c-primary-neutral-3);
      cursor: default;

      .franchise__value {
        font-weight: bold;
      }

      &:hover {
        border-color: var(--c-primary-color-3);
        color: var(--c-primary-neutral-3);
      }
    }

    .franchise {
      &__value {
        display: block;
      }

      &__difference {
        line-height: 15px;
        height: 15px;

        & :deep(*) {
          font-size: 12px;
          line-height: 15px;
          color: var(--c-secondary-neutral-2);
        }
      }
    }

    &:hover {
      border-color: var(--c-primary-color-3);
      color: var(--c-primary-color-3);
    }
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .franchise:not(.franchise--mobile-view) {
    @supports (grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))) {
      grid-gap: 0;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }

    .franchise {
      &-option {
        border: none;
        border-top: 1px solid var(--c-primary-neutral-1);
        border-left: 1px solid var(--c-primary-neutral-1);
        border-bottom: 1px solid var(--c-primary-neutral-1);
        padding: 5px;

        &--active:not(:first-child) {
          border-left-color: var(--c-primary-neutral-1) !important;
        }

        &:last-child {
          border-right: 1px solid var(--c-primary-neutral-1);
        }

        &:hover {
          border-left-color: var(--c-primary-neutral-1);

          &:first-child {
            border-left-color: var(--c-primary-color-3);
          }

          &:last-child {
            border-right-color: var(--c-primary-neutral-1);
          }
        }
      }
    }
  }

  .franchise {
    &--inline {
      grid-template-columns: repeat(auto-fit, 1fr);
      grid-template-rows: repeat(auto-fit, 1fr);
      border-top: 1px solid var(--c-primary-neutral-1);
      border-left: 1px solid var(--c-primary-neutral-1);

      .franchise-option.franchise-option {
        border: none;
        border-bottom: 1px solid var(--c-primary-neutral-1);
        border-right: 1px solid var(--c-primary-neutral-1);
      }

      .franchise-option {
        border: none;
      }
    }

    &--multi-rows {
      gap: 0;
      border-top: 1px solid var(--c-primary-neutral-1);
      border-left: 1px solid var(--c-primary-neutral-1);

      .franchise-option.franchise-option {
        border: none;
        border-bottom: 1px solid var(--c-primary-neutral-1);
        border-right: 1px solid var(--c-primary-neutral-1);
      }
    }
  }
}
</style>
