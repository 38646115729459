<template>
  <!-- prettier-ignore -->
  <div
    cy-option="duration"
    class="duration"
  >
    <Form.RadioGroup
      name="duration"
      raw
      :items="items"
      :model-value="selectedOption"
      @update:modelValue="select"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { SortUtil } from '@/utils/Sorting'
import { events$ } from '@/services'

import useI18n from '@/hooks/useI18n'

import * as Form from '@/components/Form'

import { EVENT_BASKET, EVENT_PRODUCT } from '@/config/events'

// HOOKS
const { t } = useI18n()

// INIT
const emit = defineEmits(['select'])
const props = defineProps({
  categoryId: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  productId: {
    type: String,
    required: true,
  },
  source: {
    type: String,
    required: true,
  },
})

// COMPUTED
const items = computed(() => {
  return sortedOptions.value.map((option, value) => {
    let text
    switch (option.duration.toString()) {
      case '1':
        text = `${option.duration} ${t('date.year')}`
        break

      case '3':
      case '5':
        text = `${option.duration} ${t('date.years')}`
        break

      case 'F':
        text = t('product.contractDuration.extended')
        break
    }

    return { value, text }
  })
})

const selectedOption = computed(() => {
  return sortedOptions.value.findIndex(item => item.selected)
})

const sortedOptions = computed(() => {
  const selectedIndex = props.options.findIndex(item => item.selected)
  return props.options
    .filter((option, index) => {
      const matches = props.options.filter(o => o.duration === option.duration).length
      return matches === 1 || selectedIndex === index
    })
    .sort(SortUtil.sortByParam('duration'))
})

// METHODS
function select(index) {
  const option = Object.assign({}, sortedOptions.value[index], { selected: true })
  emit('select', option)
  events$.emit(EVENT_BASKET.TRACK_AFTER_UPDATE, {
    ruleId: EVENT_PRODUCT.DURATION_CHANGED,
    payload: {
      categoryId: props.categoryId,
      source: props.source,
      productId: props.productId,
    },
  })
}
</script>
