<template>
  <!-- prettier-ignore -->
  <div
    class="action"
    :class="{'action--disabled': isDisabled}"
  >
    <template v-if="isDisabled">
      <Form.Button
        v-tooltip="tooltip"
        class="action__unavailable"
        icon-art="light"
        icon="question-circle"
        plain
        tiny
        :text="$t(title)"
      />
    </template>
    <template v-else>
      <Form.Button
        :key="title"
        v-tooltip="tooltip"
        :disabled="isDisabled"
        :has-loading-indicator="$props.hasLoadingIndicator"
        :plain="isPlain"
        :primary="isPrimary"
        :secondary="isSecondary"
        :text="$t(title)"
        :tiny="$props.tiny"
        @click.stop="actionHandler"
      />
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import useI18n from '@/hooks/useI18n'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'

import * as Form from '@/components/Form'

// HOOKS
const { t, te } = useI18n()

// INIT
const emit = defineEmits(['add', 'search', 'remove'])
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  personId: {
    type: String,
    required: true,
  },
  primary: {
    type: Boolean,
    default: false,
  },
  product: {
    type: Object,
    required: true,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  tiny: {
    type: Boolean,
    default: false,
  },
  hasLoadingIndicator: {
    type: Boolean,
    default: false,
  },
})

// COMPUTED
const isDisabled = computed(() => {
  return props.disabled || (!props.product.valid && !props.selected)
})

const isPlain = computed(() => {
  return props.disabled
})

const isPrimary = computed(() => {
  return props.primary && !isPlain.value && !props.disabled && !needsDoctor.value
})

const isSecondary = computed(() => {
  return !isPrimary.value && !isPlain.value
})

/**
 * Only show search doctor if the product is not selected.
 * If it's selected and the doctor is missing,
 * a warning message and action will be displayed in the product doctor.
 */
const needsDoctor = computed(() => {
  return props.product.medicalOffice && !props.product.doctor && !props.product.selected
})

const title = computed(() => {
  if (!props.product.valid && !props.selected) {
    return 'product.unavailable'
  } else if (props.selected) {
    return getContent(props.product.productId, 'actions.remove')
  } else if (!props.tiny && needsDoctor.value) {
    return 'product.doctor.add'
  } else {
    return getContent(props.product.productId, 'actions.add')
  }
})

const tooltip = computed(() => {
  if (isDisabled.value) {
    let content = props.product.messages
      ?.map(m => {
        return t(`error.${m.key}`)
      })
      .join('; ')
      .trim()

    if (!content) {
      content = t(getContent(props.product.productId, 'unavailable'))
    }

    return {
      allowHTML: true,
      content,
    }
  } else {
    return false
  }
})

const willReplaceIds = computed(() => {
  const { willReplace } = usePersonSpecificProduct()

  return willReplace(props.personId, props.product.productId)
})

// METHODS
function actionHandler() {
  if (isDisabled.value) return
  if (props.product.medicalOffice && needsDoctor.value) {
    emit('search')
  } else if (!props.selected && props.product.valid) {
    const productId = willReplaceIds.value
    emit('add', { replace: !!productId, productId })
  } else if (props.selected) {
    emit('remove')
  }
}

function getContent(productId, key) {
  const productPath = `content.products.${productId}`
  return te(`${productPath}.${key}`) ? `${productPath}.${key}` : `product.${key}`
}
</script>

<style name="mobile" scoped>
.action .button.plain {
  color: var(--c-primary-neutral-1) !important;

  .icon {
    color: var(--c-primary-neutral-1);
    margin: 0 0 0 5px;
  }
}

.action--disabled button,
.action--disabled button:hover {
  cursor: not-allowed !important;
  border-color: transparent !important;
  background: none !important;
  color: var(--c-primary-color-3) !important;
  fill: var(--c-primary-color-3) !important;
}

.action__unavailable {
  flex-flow: row-reverse;
  gap: 4px;
}
</style>
