<template>
  <!-- prettier-ignore -->
  <div
    v-if="isEnabled"
    class="doctor"
  >
    <template v-if="hasDoctor">
      <div class="doctor-present">
        <div class="doctor-info">
          <the-icon
            v-tooltip="$t('product.doctor.title')"
            class="doctor__title"
            art="duo"
            name="house-medical"
          />
          <div
            class="doctor__name"
            v-text="doctorName"
          />
          <a
            class="doctor__search"
            @click="search"
          >
            <the-icon
              art="light"
              name="pencil"
            />
          </a>
        </div>

        <template v-if="discountGroup">
          <div class="doctor-discount">
            <div v-show="false">
              <div :id="`discountGroup_${$props.product.productId}`">
                <h4 v-text="$t('medicalofficesearch.reductions.title')" />
                <div v-text="$t('medicalofficesearch.reductions.text')" />
                <div>
                  <div>
                    <the-icon
                      class="doctor__icon"
                      art="solid"
                      name="signal-good"
                    />
                    {{ $t('medicalofficesearch.reductions.level.highest') }}
                  </div>
                  <div>
                    <the-icon
                      class="doctor__icon"
                      art="solid"
                      name="signal-fair"
                    />
                    {{ $t('medicalofficesearch.reductions.level.medium') }}
                  </div>
                  <div>
                    <the-icon
                      class="doctor__icon"
                      art="solid"
                      name="signal-weak"
                    />
                    {{ $t('medicalofficesearch.reductions.level.lowest') }}
                  </div>
                </div>
                <br>
                <div v-html="$t('medicalofficesearch.reductions.note')" />
                <br>
              </div>
            </div>

            <div
              v-if="!isEmpty(discountTooltip)"
              class="doctor-discount__title"
            >
              <span v-tooltip="discountTooltip">
                {{ $t('product.reduction')}}
                <the-icon
                  class="doctor__icon"
                  art="solid"
                  :name="discountIcon"
                />
              </span>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template v-else-if="!!transferableDoctorOtherPerson">
      <div class="doctor-persontransfer">
        <div class="doctor__transfer-other-info">
          <div
            class="doctor__title"
            v-text="$t('product.applyProductAndDoctor', { name: transferableDoctorOtherPersonName })"
          />
          <div v-text="doctorNameTransferableDoctorOtherPerson" />
        </div>
        <Form.Button
          key="transfer"
          secondary
          tiny
          :text="$t('product.actions.add')"
          @click="transferDoctorFromOtherPerson"
        />
      </div>
    </template>
    <template v-else-if="$props.product.selected">
      <the-message severity="warning">
        <div class="doctor-missing">
          <div
            class="doctor__title"
            v-text="$t('product.doctor.missing')"
          />
          <div class="doctor-actions">
            <Form.Button
              key="search"
              icon-color="original-green"
              icon="pen"
              secondary
              :text="$t(`content.products.${$props.product.productId}.searchMedicalOffice`)"
              @click="search"
            />
          </div>
        </div>
      </the-message>
    </template>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import isEmpty from 'lodash/isEmpty'

import { events$, loading$ } from '@/services'

import { logError } from '@/helpers/Logger'
import { getDoctorName, getTransferableDoctorFromOtherPerson } from '@/utils/Doctor'
import { confirm } from '@/utils/Modal'

import basketStore from '@/store/basket'
import useI18n from '@/hooks/useI18n'
import usePerson from '@/hooks/usePerson'
import usePersonDetails from '@/hooks/usePersonDetails'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useProduct from '@/hooks/useProduct'
import useProductPersons from '@/hooks/useProductPersons'

import * as Form from '@/components/Form'

import {
  AVM_TYPE_CARE_MED,
  AVM_TYPE_NET_MED,
  AVM_TYPE_MED_BASE,
  productAVMtypes,
} from '@/components/MedicalSearch/config/constants'

import { EVENT_PRODUCT, EVENT_TRANSFER_DOCTOR_FROM_OTHER } from '@/config/events'

// HOOKS
const { t } = useI18n()
const { getFirstName, persons } = usePerson()
const { getPersonWithDetails } = usePersonDetails()
const { hasPersonOnlyVVG, isProductInBasket } = usePersonSpecificProduct()
const { getAVMId, getCategoryIdFromProduct, getSelectedProductsOfPerson } = useProduct()
const { addProductDoctorToBasket } = useProductPersons()

// INIT
const emit = defineEmits(['search'])
const props = defineProps({
  personId: {
    type: String,
    required: true,
  },
  product: {
    type: Object,
    required: true,
  },
  source: {
    type: String,
    required: true,
  },
})

// DATA
const discountTooltip = ref({})

// COMPUTED
const discountGroup = computed(() => {
  if (!props.product.showDiscountGroup || !doctor.value) return false
  const network = doctor.value.medicalOfficeNets.find(n => n.avmDefinition === networkId.value)
  return network?.discountGroup
})

const discountIcon = computed(() => {
  const group = parseInt(discountGroup.value)
  switch (group) {
    case 1:
    default:
      return 'signal-good'

    case 2:
      return 'signal-fair'

    case 3:
      return 'signal-weak'
  }
})

const doctor = computed(() => {
  return props.product.doctor
})

const doctorName = computed(() => {
  return getDoctorName(doctor.value)
})

const doctorNameTransferableDoctorOtherPerson = computed(() => {
  return getDoctorName(transferableDoctorOtherPerson.value?.doctor)
})

const hasDoctor = computed(() => {
  return !isEmpty(props.product.doctor)
})

const isEnabled = computed(() => {
  return (
    props.product.medicalOffice &&
    (hasDoctor.value || props.product.selected || isTransferable.value || !!transferableDoctorOtherPerson.value)
  )
})

const isTransferable = computed(() => {
  const productId = props.product.productId
  if (!isProductInBasket(props.personId, productId) || !doctor.value) {
    return false
  }

  const ident = productAVMtypes[productId]
  if (AVM_TYPE_CARE_MED !== ident) {
    return false
  }

  const searchKey = ident === AVM_TYPE_NET_MED ? AVM_TYPE_CARE_MED : AVM_TYPE_NET_MED
  return !!doctor.value.medicalOfficeNets.find(n => n.avmDefinition === searchKey)
})

const networkId = computed(() => {
  return getAVMId(props.product.productId)
})

const transferableDoctorOtherPerson = computed(() => {
  // no transfer if person already has kvg
  const person = getPersonWithDetails(props.personId)
  if (getSelectedProductsOfPerson(person.products.products).length > 0 && !hasPersonOnlyVVG(props.personId)) {
    return null
  }

  return getTransferableDoctorFromOtherPerson(persons.value, props.personId)
    .filter(c => Object.values(c).filter(v => v === undefined).length === 0)
    .find(c =>
      c.doctor.medicalOfficeNets.find(n => {
        const candidateNetworkId = getAVMId(c.productId)
        const hmoProducts = [AVM_TYPE_CARE_MED, AVM_TYPE_NET_MED]

        // medbase doctors only shown if source product is MedBase, caremed/netmed only if source is caremed or netmed
        if (
          (candidateNetworkId === AVM_TYPE_MED_BASE && networkId.value !== AVM_TYPE_MED_BASE) ||
          (hmoProducts.includes(candidateNetworkId) && !hmoProducts.includes(networkId.value))
        ) {
          return false
        }
        return n.avmDefinition === networkId.value
      })
    )
})

const transferableDoctorOtherPersonName = computed(() => {
  const __personId = transferableDoctorOtherPerson.value?.source
  if (!__personId) {
    return ''
  }
  return getFirstName(__personId)
})

const productCategory = computed(() => {
  return getCategoryIdFromProduct(props.product.productId)
})

// Watchers
watch(
  () => discountGroup,
  value => {
    if (value) {
      getDiscountContent()
    }
  },
  { immediate: true }
)

watch(
  () => transferableDoctorOtherPerson,
  value => {
    if (value) {
      events$.emit(EVENT_TRANSFER_DOCTOR_FROM_OTHER.DISPLAYED)
    }
  },
  { immediate: true }
)

// METHODS
function applyToBasket(__doctor, productId, categoryId, appliedFromOther = false) {
  if (loading$.getState().active.value) {
    return
  }
  loading$.start()

  return addProductDoctorToBasket(props.personId, productId, __doctor)
    .then(basket => basketStore.updateOvpBasket(basket))
    .then(basket => {
      events$.emit(EVENT_PRODUCT.ADDED, {
        basket,
        categoryId,
        personId: props.personId,
        productId,
        source: props.source,
      })
      return basket
    })
    .then(basket => {
      if (appliedFromOther) {
        events$.emit(EVENT_TRANSFER_DOCTOR_FROM_OTHER.APPLIED)
      }
      return basket
    })
    .catch(error => logError(error))
    .finally(() => loading$.end())
}

function getDiscountContent() {
  const __el = document.getElementById(`discountGroup_${props.product.productId}`)
  if (__el) {
    Object.assign(discountTooltip, {
      allowHTML: true,
      content: __el,
    })
  } else {
    window.setTimeout(() => {
      getDiscountContent()
    }, 1000)
  }
}

function search() {
  emit('search')
}

async function transferDoctorFromOtherPerson() {
  const apply = () => {
    applyToBasket(transferableDoctorOtherPerson.value?.doctor, props.product.productId, productCategory.value, true)
  }

  if (transferableDoctorOtherPerson.value?.doctor.medicalOfficeStatus === 'ACCEPTS_NOBODY') {
    const type = productAVMtypes[props.product.productId].toLowerCase()
    const title = t(`medicalofficesearch.messages.office.title.${type}`)
    const description = t(`medicalofficesearch.messages.office.description.${type}`)

    const label = `${title}. ${description}`
    if (await confirm({ label })) {
      apply()
    }
  } else {
    apply()
  }
}

// WATCHERS
watch(
  () => discountGroup,
  value => {
    if (value) {
      getDiscountContent()
    }
  },
  { immediate: true }
)
watch(
  () => transferableDoctorOtherPerson,
  value => {
    if (value) {
      events$.emit(EVENT_TRANSFER_DOCTOR_FROM_OTHER.DISPLAYED)
    }
  },
  { immediate: true }
)
</script>

<style name="mobile" scoped>
.doctor {
  &-present {
    border-top: 1px solid var(--c-primary-neutral-1);
    border-bottom: 1px solid var(--c-primary-neutral-1);
    padding: 8px 0;

    .doctor-info {
      display: flex;

      .doctor {
        &__title {
          color: var(--c-primary-neutral-1);
          flex: 0 0 25px;
        }

        &__name {
          padding: 0 5px;
          font-size: 14px;
        }

        &__search {
          flex: 0 0 25px;
          margin-left: auto;
        }
      }
    }

    .doctor-discount {
      margin: 0 0 0 30px;
      font-size: 14px;
    }

    .doctor-transfer {
      &.message {
        background-color: transparent;
        padding: 5px 5px 0 5px;
      }
    }
  }
}
</style>
