import config from '@/config/index'

function resources() {
  // METHODS
  async function getContentByPath(path) {
    const response = await fetch(config.resourceBaseUrl + path)
    return await response.json()
  }

  return {
    getContentByPath,
  }
}

export default resources()
