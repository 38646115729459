<template>
  <!-- prettier-ignore -->
  <the-message
    v-if="hasTransferRight"
    theme="dark"
    v-html="$t('screen.products.noRiskAssessmentRequired')"
  />
</template>

<script setup>
import { computed } from 'vue'

import { DateTimeUtil } from '@/utils/DateTime'

import basketStore from '@/store/basket'
import useBasket from '@/hooks/useBasket'
import useContract from '@/hooks/useContract'
import usePersonDetails from '@/hooks/usePersonDetails'

// HOOKS
const { isExistingCustomerBasket } = useBasket()
const { contractPersons, getContractProduct } = useContract()
const { getPersonWithDetails } = usePersonDetails()

// INIT
const props = defineProps({
  personId: {
    type: String,
    required: true,
  },
  productId: {
    type: String,
    required: true,
  },
})

// DATA
const PRODUCTS = {
  CLASSIC: 'AB04Produkt',
  FAMILY: 'AB404Produkt',
  JUMP: 'AB304Produkt',
  VITAL_SMART: 'SAMB06MProdukt',
  VITAL_PREMIUM: 'SAMB06LProdukt',
}
const TRANSFERABLE_PRODUCTS = [PRODUCTS.VITAL_SMART, PRODUCTS.VITAL_PREMIUM]
const VITAL_START_DATE = '2024-01-02'

// COMPUTED
const hasTransferRight = computed(() => {
  if (!isExistingCustomerBasket.value) return false
  if (!TRANSFERABLE_PRODUCTS.includes(props.productId)) return false

  const person = getPersonWithDetails(props.personId)
  if (!person.partnerNumber) return false

  const hasFoundContractPerson = contractPersons.value.find(
    p => p.personId === basketStore.basket.persons.find(q => q.personId === props.personId).partnerNumber
  )

  if (!hasFoundContractPerson) return false

  switch (props.productId) {
    case PRODUCTS.VITAL_SMART: {
      // If person has jump in contract he has transfer rights to Vital Smart with 2500/400
      const hasJumpProduct = !!getContractProduct(person.partnerNumber, PRODUCTS.JUMP)
      if (hasJumpProduct) {
        const selectedCriteria = getSelectedCriteria(props.productId)
        return selectedCriteria.amValue === 2500 && selectedCriteria.gfValue === 400
      }

      // If person has classic in contract he has transfer rights to Vital Smart with 5000/400
      const hasClassicProduct = !!getContractProduct(person.partnerNumber, PRODUCTS.CLASSIC)
      if (hasClassicProduct) {
        const selectedCriteria = getSelectedCriteria(props.productId)
        return selectedCriteria.amValue === 5000 && selectedCriteria.gfValue === 400
      }

      // If person has family in contract and is older than 18 at the end of the calendar year
      // of the vital start date he has transfer rights to Vital Smart with 5000/400
      const hasFamilyProduct = !!getContractProduct(person.partnerNumber, PRODUCTS.FAMILY)
      if (hasFamilyProduct) {
        const age = DateTimeUtil.getAgeEndOfContractStartYear(person.personData.dateOfBirth, VITAL_START_DATE)
        const selectedCriteria = getSelectedCriteria(props.productId)
        return age > 18 && selectedCriteria.amValue === 5000 && selectedCriteria.gfValue === 400
      }
      break
    }
    case PRODUCTS.VITAL_PREMIUM: {
      // If person has family in contract and is younger than 19 ath the end of the calendar year
      // of the vital start date he has transfer rights to Vital Premium
      const hasFamilyProduct = !!getContractProduct(person.partnerNumber, PRODUCTS.FAMILY)
      const age = DateTimeUtil.getAgeEndOfContractStartYear(person.personData.dateOfBirth, VITAL_START_DATE)
      return hasFamilyProduct && age < 19
    }
  }

  return false
})

// METHODS
function getSelectedCriteria(productId) {
  const personProducts = basketStore.basket.persons.find(p => p.personId === props.personId).products.products
  return personProducts[productId].prices.find(p => p.selected)
}
</script>
<style name="mobile" scoped></style>
