<template>
  <!-- prettier-ignore -->
  <div
    ref="benefitsTableRef"
    class="benefitsTable"
  >
    <div class="table">
      <table>
        <thead>
          <tr>
            <th>
              &nbsp;
            </th>
            <template
              v-for="(item, index) in $props.benefitsData.header.contentArray"
              :key="index"
            >
              <th :class="{ 'selected': index === $props.benefitsData.selectedContentColumn }">
                <div class="thContent">
                  <div>
                    <the-typography
                      v-if="hasSubtitles"
                      type="bodyMediumLong"
                      v-text="item.subTitle || '&nbsp;'"
                    />

                    <the-typography
                      type="heading04"
                      v-text="item.title"
                    />
                  </div>

                  <template v-if="item.productLink">
                    <a
                      class="productLink-link"
                      :href="getUrl(item.productLink)"
                    >
                      <the-icon
                        class="productLink-icon"
                        art="solid"
                        name="arrow-right-long"
                      />
                      <span v-text="$t('benefits.widget.productLinkText')" />
                    </a>
                  </template>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody class="rows">
          <tr
            v-for="(row, rowIndex) in $props.benefitsData.content"
            :key="rowIndex"
            class="row"
          >
            <td class="row__title">
              <the-icon
                v-if="row.icon"
                class="row-title-icon"
                art="light"
                :name="row.icon"
              />
              <the-typography
                :type="row.isMainType ? 'heading06' : 'bodyMediumLong'"
                v-html="row.title"
              />
            </td>

            <template
              v-for="(rowContent, rowContentIndex) in row.content"
              :key="rowContentIndex">
              <td
                :class="{ 'selected': rowContentIndex === $props.benefitsData.selectedContentColumn }"
                v-html="rowContent"
              />
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, createVNode, render, getCurrentInstance, ref, watchEffect } from 'vue'

import tippy from 'tippy.js'

import useApplication from '@/hooks/useApplication'
import useI18n from '@/hooks/useI18n'

// HOOKS
const { isReadyWithPartner } = useApplication()
const { t } = useI18n()
const instance = getCurrentInstance()

// INIT
const props = defineProps({
  benefitsData: {
    type: Object,
    required: true,
  },
})

// DATA
const benefitsTableRef = ref(undefined)
// @TODO Try out with new circumstances whether this is still needed
let __Icon
if (instance?.appContext?.components?.Icon) {
  __Icon = createVNode(instance?.appContext?.components?.Icon, { art: 'light', name: 'circle-question' })
}

// COMPUTED
const hasSubtitles = computed(() => {
  return props.benefitsData.header.contentArray.some(item => !!item.subTitle)
})

// METHODS
function addTooltips() {
  const elements = benefitsTableRef.value?.querySelectorAll('[data-tooltip]')
  const content = t('benefits.tooltip')

  elements?.forEach(node => {
    const tooltipKey = node.dataset?.tooltip.toLowerCase()
    const tooltip = content[tooltipKey] ? content[tooltipKey] : null

    if (tooltip) {
      node.setAttribute('class', 'benefits__tooltip')
      node.setAttribute('v-tooltip', tooltip)
      node.innerHTML = node.innerHTML + ''

      tippy(node, {
        trigger: 'mouseenter focus click',
        allowHTML: true,
        appendTo: document.body,
        arrow: true,
        content: tooltip,
        interactive: true,
        theme: 'material',
      })

      render(__Icon, node)
    }
  })
}

function getUrl(productLink) {
  return `${import.meta.env.VITE_SANITAS_PATH}${productLink}`
}

// WATCHERS
watchEffect(() => {
  if (isReadyWithPartner.value) {
    addTooltips()
  }
})
</script>

<style name="global">
.benefits__tooltip {
  cursor: pointer;
  border-bottom: 2px dotted var(--c-primary-color-3);
}

.table {
  em {
    font-style: normal;
    font-weight: bold;
  }

  ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ul li {
    font-size: 14px;
    line-height: 20px;
    color: var(--c-secondary-neutral-1);
  }
}
</style>

<style name="mobile" scoped>
.table {
  background-color: var(--c-primary-neutral-3);
  font-size: 14px;
  line-height: 20px;
  color: var(--c-secondary-neutral-1);
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;

  table {
    th,
    td {
      padding: 8px 16px;
      min-width: 220px;
    }
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;

    tr {
      height: 100%;
    }

    @supports (-moz-appearance: none) {
      th {
        height: 100% !important;
      }
    }

    th {
      min-width: 220px;
      vertical-align: top;
      text-align: left;
      height: 0;
      color: var(--c-primary-neutral-1);

      .thContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
        height: 100%;

        a {
          color: var(--c-secondary-neutral-1);

          span {
            margin: 0 0 0 8px;
          }
        }
      }
    }

    tr {
      border-bottom: 1px solid var(--c-primary-neutral-1);
    }

    td {
      color: var(--c-primary-neutral-1);
    }
  }

  .row {
    &__title {
      display: flex;
      gap: 16px;
      color: var(--c-primary-neutral-1);

      .icon {
        font-size: 20px;
        color: var(--c-primary-neutral-1);
      }
    }
  }

  .selected {
    background-color: var(--c-primary-color-2-d);
  }
}
</style>
