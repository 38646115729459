<template>
  <!-- prettier-ignore -->
  <div
    class="vitalFranchise"
    cy-option="vitalFranchise"
  >
    <div class="product__criteria">
      <slot name="amValue" />

      <div
        class="franchise"
        :class="{'franchise--mobile': $props.forcedMobile}"
      >
        <transition-group
          appear
          name="hello"
        >
          <template
            v-for="option in values[AM_KEY]"
            :key="option.value"
          >
            <button
              class="franchise-option"
              :class="{'franchise-option--active': !!option.selected}"
              :cy-test="option.value"
              @click="select(AM_KEY, option)"
            >
              <the-typography
                class="franchise__value"
                tag="span"
                type="bodyLargeShort"
                v-text="getFormattedCurrency(option.value, null, 0)"
              />
            </button>
          </template>
        </transition-group>
      </div>
    </div>

    <div class="product__criteria">
      <slot name="gfValue" />

      <div
        class="franchise"
        :class="{'franchise--mobile': $props.forcedMobile}"
      >
        <transition-group
          appear
          name="hello"
        >
          <template
            v-for="option in values[GF_KEY]"
            :key="option.value"
          >
            <button
              class="franchise-option"
              :class="{'franchise-option--active': !!option.selected}"
              :cy-test="option.value"
              @click="select(GF_KEY, option)"
            >
              <the-typography
                class="franchise__value"
                tag="span"
                type="bodyLargeShort"
                v-text="getFormattedCurrency(option.value, null, 0)"
              />
            </button>
          </template>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { events$ } from '@/services'

import useFranchise from '@/hooks/useFranchise'

import { EVENT_PRODUCT, EVENT_BASKET } from '@/config/events'

// INIT
const AM_KEY = 'amValue'
const GF_KEY = 'gfValue'
const emit = defineEmits(['select'])
const props = defineProps({
  forcedMobile: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    required: true,
  },
  source: {
    type: String,
    required: true,
  },
})

// COMPUTED
const selectedOption = computed(() => {
  return props.options.find(o => o.selected)
})

const values = computed(() => {
  const data = { [AM_KEY]: {}, gfValue: {} }

  props.options.forEach(option => {
    if (!data[AM_KEY][option[AM_KEY]]) {
      data[AM_KEY][option[AM_KEY]] = {
        value: option[AM_KEY],
        selected: selectedOption.value[AM_KEY] === option[AM_KEY],
      }
    }

    if (!data[GF_KEY][option[GF_KEY]]) {
      data[GF_KEY][option[GF_KEY]] = {
        value: option[GF_KEY],
        selected: selectedOption.value[GF_KEY] === option[GF_KEY],
      }
    }
  })

  return data
})

// METHODS
function getFormattedCurrency(input, currency, fixed) {
  const { formatCurrency } = useFranchise()
  return formatCurrency(input, currency, fixed)
}

function select(key, option) {
  // If duration is set to 'F', there are no available options in the prices array to select from,
  // so according to FLEX-1881 we select the 1 year duration when the vital product is changed
  const duration = selectedOption.value.duration === 'F' ? '1' : selectedOption.value.duration
  const match = props.options.find(o => {
    return (
      o.duration === duration &&
      ((key === GF_KEY && o[AM_KEY] === selectedOption.value[AM_KEY] && o[key] === option.value) ||
        (key === AM_KEY && o[GF_KEY] === selectedOption.value[GF_KEY] && o[key] === option.value))
    )
  })

  const newOption = Object.assign({}, match, { selected: true })
  emit('select', newOption)
  events$.emit(EVENT_BASKET.TRACK_AFTER_UPDATE, {
    ruleId: EVENT_PRODUCT.FRANCHISE_CHANGED,
    payload: props.source,
  })
}
</script>

<style name="animations" scoped>
.hello-enter-active,
.hello-leave-active {
  transition: all 0.75s ease;
}

.hello-enter-from,
.hello-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
</style>

<style name="mobile" scoped>
.vitalFranchise {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.franchise {
  overflow: hidden;
  display: flex;
  gap: 16px;

  &-option {
    border: 1px solid var(--c-primary-neutral-1);
    background: none;
    width: 100%;
    height: 50px;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: var(--c-primary-neutral-1);

    &--active.franchise-option {
      border-color: var(--c-primary-color-3);
      background-color: var(--c-primary-color-3);
      color: var(--c-primary-neutral-3);
      cursor: default;

      .franchise__value {
        font-weight: bold;
      }

      &:hover {
        border-color: var(--c-primary-color-3);
        color: var(--c-primary-neutral-3);
      }
    }

    .franchise {
      &__value {
        display: block;
      }

      &__difference {
        line-height: 15px;
        height: 15px;

        & :deep(*) {
          font-size: 12px;
          line-height: 15px;
          color: var(--c-secondary-neutral-2);
        }
      }
    }

    &:hover {
      border-color: var(--c-primary-color-3);
      color: var(--c-primary-color-3);
    }
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .franchise {
    gap: 0;

    &--mobile {
      gap: 16px;
    }

    &-option {
      max-width: 150px;
    }
  }
}
</style>
