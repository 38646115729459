<template>
  <!-- prettier-ignore -->
  <div
    class="accident"
    cy-option="accident"
  >
    <Form.Toggle
      v-model="toggleValue"
      name="accident"
      @update:modelValue="onChange"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

import * as Form from '@/components/Form'

// INIT
const emit = defineEmits(['select'])
const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
})

// DATA
const toggleValue = ref(props.option.toggle)

// METHODS
function onChange(toggle) {
  const option = Object.assign({}, props.option, { toggle: toggle })
  emit('select', option)
}

// WATCHERS
watch(
  () => props.option,
  __value => {
    toggleValue.value = __value.toggle
  }
)
</script>

<style name="mobile" scoped>
.accident {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 0;
}
</style>
